<div class="access-container__content page-content">
  <div class="page-content__header">
    <div class="page-content__title"
         [innerHTML]="'ROOT.LOGIN_PAGE.WELCOME_TITLE' | translate">
    </div>

    <div class="page-content__sub-title">
      {{ 'ROOT.LOGIN_PAGE.LOGIN_TO_ACCOUNT' | translate }}
    </div>
  </div>

  <form class="page-content__form"
        autocomplete="off"
        [formGroup]="form"
        (ngSubmit)="login()">
    <am-input [control]="form.controls['email']"
              placeholder="ROOT.LOGIN_PAGE.EMAIL_PLACEHOLDER"
              data-test="login"
              #emailControl>
    </am-input>

    <am-input [control]="form.controls['password']"
              [type]="isPasswordExposed ? 'text' : 'password'"
              data-test="password"
              placeholder="ROOT.LOGIN_PAGE.PASSWORD_PLACEHOLDER"
              #passwordControl>
      <button *ngIf="form.value.password"
              suffix
              type="button"
              class="icon"
              [ngClass]="isPasswordExposed ? 'icon-eye-off-filled' : 'icon-eye-filled'"
              (click)="isPasswordExposed = !isPasswordExposed">
      </button>
    </am-input>

    <div class="page-content__helpers">
      <mpp-checkbox [control]="form.controls.rememberMe">
        {{ 'ROOT.LOGIN_PAGE.REMEMBER_ME' | translate }}
      </mpp-checkbox>

      <a class="page-content__link"
         routerLink="./{{ FORGOT_PASSWORD_PATH }}">
        {{ 'ROOT.LOGIN_PAGE.FORGOT_PASSWORD' | translate }}
      </a>
    </div>

    <div class="page-content__buttons">
      <!-- TODO: Update button style from Design system -->
      <button class="page-content__button"
              mppButton="fill blue"
              type="submit"
      >
        {{ 'ROOT.LOGIN_PAGE.LOGIN_BUTTON' | translate }}
      </button>
    </div>
  </form>

  <div class="page-content__footer">
    <div class="content-policy"
         [innerHTML]="'ROOT.LOGIN_PAGE.POLICY' | translate | mppSanitizeHtml"
    ></div>
  </div>
</div>

<div class="access-container__preview">
  <div class="access-preview"
       data-background-cover
       [style.background-image]="'url(' + employeeInfo.imageUrl + ')'"
  >
    <a class="access-preview__logo-wrapper" href="https://www.arturin.com/">
      <img class="access-preview__logo"
           src="../../assets/images/logo-with-shadow.svg"
           alt="" />
    </a>

    <div class="rt-user-signature">
      <div class="rt-user-signature__info">
        <mpp-user-avatar class="rt-user-signature__avatar"
                         [name]="employeeInfo.name">
        </mpp-user-avatar>

        <div class="rt-user-signature__description">
          <div class="rt-user-signature__name">
            {{ employeeInfo.name }}
          </div>

          <div class="rt-user-signature__job">
            {{ employeeInfo.jobTitle | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
